* {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #f6f6f6;
}

/* payment gateway disabled columns */
.card-group{display: none;}

div[ng-if="option.requireContact"]{
  display: none;
}

div[ng-hide="isConfirmingInformation"]{
  display: none;
}

#fsc-popup-frame email{display: none;}

.PhoneInputInput:hover {
  border-color:  black !important;
}

.PhoneInputInput:focus {
  outline:  #1976D2 auto 1px;
}

@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 400; 
  font-display: swap; 
  src: url("assets/fonts/Inter-Regular.woff2") format("woff2"); 
}

@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 500; 
  font-display: swap; 
  src: url("assets/fonts/Inter-Medium.woff2") format("woff2"); 
}

@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 600; 
  font-display: swap; 
  src: url("assets/fonts/Inter-SemiBold.woff2") format("woff2"); 
}

@font-face { 
  font-family: "Inter"; 
  font-style: normal; 
  font-weight: 700; 
  font-display: swap; 
  src: url("assets/fonts/Inter-Bold.woff2") format("woff2"); 
}