:root {
    --image-size : 36px;
    --container-size : 40px;
    --circle-size : 40px;
    --border-color : #EFD016;
    --border-width : 2px;

    --image-size-l : 70px;
    --container-size-l : 70px;
    --circle-size-l : 70px;
    --border-width-l : 4px;
}

 .logo-container {
    position: relative;
    width: var(--container-size); 
    height: var(--container-size);
  }
  
  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width : var(--image-size);
  }
  
  .animated-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--container-size); 
    height: var(--container-size);
    border-left: var(--border-width) solid var(--border-color); 
    border-right: var(--border-width) solid var(--border-color); 
    border-radius: 50%;
    animation: rotating-circle 2s linear infinite; 
  }
  
  .logo-container-l {
    position: relative;
    width: var(--container-size-l); 
    height: var(--container-size-l); 
  }
  
  .logo-image-l {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width : var(--image-size-l);
  }
  
  .animated-circle-l {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: var(--container-size-l);
    height: var(--container-size-l);
    border-left: var(--border-width-l) solid var(--border-color); 
    border-right: var(--border-width-l) solid var(--border-color); 
    border-radius: 50%;
    animation: rotating-circle 2s linear infinite; 
  }

  @keyframes rotating-circle {
    to { transform: translate(-50%, -50%) rotate(360deg); }
  }
  